export const workData = [
  {
    title:        "Designer & project manager",
    year:         "December 2016 - July 2020",
    company:      "heretonow GmbH",
    companyLink:  "https://www.heretonow.de",
    city:         "Berlin, Germany"
  },
  {
    title:        "Frontend developer & designer",
    year:         "July 2020 - August 2023",
    company:      "heretonow GmbH & BESL event agency",
    companyLink:  "https://www.heretonow.de",
    city:         "Berlin, Germany"
  },
  {
    title:        "Frontend developer",
    year:         "September 2022 - now",
    company:      "Digitas Pixelpark - Publicis Groupe",
    companyLink:  "https://www.digitaspixelpark.com/",
    city:         "Berlin, Germany"
  }
]

export const educationData = [
  {
    title:        "Bachelor's Degree, Media Design",
    year:         "2012 - 2015",
    company:      "MD.H Berlin",
    companyLink:  "https://www.mediadesign.de/en",
    city:         "Berlin, Germany"
  },
  {
    title:        "Web Development Bootcamp",
    year:         "September - December 2020",
    company:      "Le Wagon",
    companyLink:  "https://www.lewagon.com/",
    city:         "Tokyo, Japan"
  }
]
