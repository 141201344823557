import React, { useState } from "react";
import { BiMoon, BiSun } from "react-icons/bi";

export default function ThemeToggle(props) {

  const [isClicked, setIsClicked] = useState(false);

  const toggleClass = () => {
    let element = document.documentElement;
    element.classList.toggle("dark");
    setIsClicked(isClicked => !isClicked);
  }

  return (
    <>
      <button className="mx-auto dark-mode-btn opacity-40 hover:opacity-80" onClick={toggleClass}>
        {isClicked ? <BiSun className="text-xl"/> : <BiMoon className="text-xl"/>}
      </button>
    </>
  );
}
