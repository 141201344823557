import React from 'react';
import Sidebar from './sections/Sidebar';
import Intro from "./sections/Intro";
import Bio from './sections/Bio';
import Experience from './sections/Experience';
import Skills from './sections/Skills';
import Projects from './sections/Projects';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import Spotlight from './sections/Spotlight';


function App() {
  const sideStructure = [ 'Intro', 'Me', 'Experience', 'Skills', 'Projects', 'Contact' ];

  return (
    <ParallaxProvider>
      <div className="App bg-light dark:bg-dark">
          {/* Use for style fixes:  <ViewportDisplay /> */}
          <Sidebar links={ sideStructure }/>
          <Intro title={sideStructure[0]} next={sideStructure[1]}/>
          <Bio title={sideStructure[1]} next={sideStructure[2]}/>
          <Experience title={sideStructure[2]} next={sideStructure[3]}/>
          <Skills title={sideStructure[3]} next={sideStructure[4]}/>
          <Projects title={sideStructure[4]} next={sideStructure[5]}/>
          <Contact title={sideStructure[5]}/>
          <Footer />
          <Spotlight />
      </div>
    </ParallaxProvider>
  );
}

export default App;
