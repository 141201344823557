import React, { useEffect } from "react";
import Heading from "../components/heading";
import Link from "../components/link";
import Timeline from "../components/timeline";
import { Parallax } from "react-scroll-parallax";
import { useMediaQuery } from 'react-responsive';
import { workData, educationData } from "../data/experience-data";
import EducationIcon from "../assets/images/3d-images/academic-hat.png";
import WorkIcon from "../assets/images/3d-images/briefcase.png";
import { useInView } from "react-intersection-observer";

export default function Experience (props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.6,
  });

  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const image = document.getElementById("backgroundImage");
    if (inView && !isSmViewport) {
      image.style.left = "60%";
      image.style.top = "60%";
      image.style.opacity = .5;
      image.style.width = "750px";
      image.style.height = "750px";
    }
  })

  function getCurrentMonth() {
    let date = new Date().toLocaleString('default', { month: 'long', year: "numeric" });
    return date;
  };

  return (
    <section id={props.title} ref={ref} className="min-h-screen w-full pl-0 sm:pl-20 z-10 relative flex items-center">
      <div className="container grid grid-cols-12 gap-5 md:gap-10 mx-auto py-20 sm:py-30 px-12 md:px-30">

        {/* Text */}
        <div className="col-span-12 md:col-span-4 lg:col-span-3 col-start-1">
          <Heading title={`Work &\neducation`} headingLevel="h1" className="new-line"/>
          <Parallax translateY={[15, -15]}>
            <div className="text-center md:text-left mb-4 lg:mb-0">
              <p>After graduating in media design, I worked for several years as a designer and project manager for digital projects at a creative agency in Berlin. Since 2022 I am working as a front-end developer.</p>
              <Link to={props.next} title={props.next}/>
            </div>
          </Parallax>
        </div>

        {/* Timeline */}
        <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-10 xs:mb-4 sm:mb-12 md:mb-8 lg:mb-0">
          <div className="grid grid-cols-2 gap-8 md:gap-16">
            <Parallax translateY={[15, -15]} disabled={isSmViewport ? true : false}>
              <Timeline data={educationData} align="right" title="Education" ongoing="Learning: Lit"/>
            </Parallax>
            <Parallax translateY={[-15, 15]} disabled={isSmViewport ? true : false}>
              <Timeline data={workData} align="left" title="Work" ongoing={getCurrentMonth()}/>
            </Parallax>
          </div>
        </div>
      </div>

      {/* Deco elements */}
      <Parallax
        translateY={[-10, 10]}
        className="absolute top-[28%] left-[10%] xs:top-[37%] xs:left-[12%] sm:left-[25%] md:top-[8%] md:left-[45%]"
        disabled={isSmViewport ? true : false}
        >
        <img src={EducationIcon} alt="3d academic hat" className="drop-shadow w-24 md:w-32 lg:w-36 xl:w-40"/>
      </Parallax>
      <Parallax
        translateY={[-10, 10]}
        className="absolute bottom-[0%] right-[5%] sm:bottom-[2%] md:bottom-[-5%] lg:bottom-[5%]"
        disabled={isSmViewport ? true : false}
        >
        <img src={WorkIcon} alt="3d academic hat" className="drop-shadow w-28 sm:w-32 md:w-32 lg:w-36 xl:w-40 -rotate-12"/>
      </Parallax>
    </section>
  );
}
