import React from "react";

export default function Link(props) {
  return (
    <>
      <a href={`#${props.to}`} className="line-hover-dark dark:line-hover-light">
        <span className="font-bold">Next: </span>
        {props.title}
      </a>
    </>
  );
}
