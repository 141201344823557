import React from "react";
import BackgroundImage from "../assets/images/bg-deco.png";

export default function Spotlight(props) {
  return (
    <>
      <img
      id="backgroundImage"
      src={BackgroundImage}
      alt="Background"
      className="fixed -translate-x-1/2 -translate-y-1/2 z-0 aspect-square transition-all duration-500 ease-in-out blur-md"/>
    </>
  )
}
