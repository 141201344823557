import React, { useState } from "react";
import { Formik, Form } from "formik";
import emailjs from '@emailjs/browser';
import { HiCheckCircle } from "react-icons/hi";
import FormField from "./formfield";

export default function ContactForm(props) {
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const sendEmail = (e) => {
    //e.preventDefault(); // prevents the page from reloading when you hit “Send”
    emailjs.sendForm('service_ahd0k3g', 'template_8c4jpzw', '#contactForm', 'pQlyzCU3gHmXPL38E')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return(
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: ''
      }}

      onSubmit={(values, { setSubmitting, resetForm }) => {
        setIsSubmitted(false);
        setTimeout(() => {
          // alert(JSON.stringify(values, null, 2));
          sendEmail();
          setSubmitting(false);
          setIsSubmitted(true);
          resetForm();
        }, 500);
      }}
    >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValid
      /* and other goodies */
    }) => (
      <Form id="contactForm" onSubmit={handleSubmit}>
        <FormField
          type="text"
          name="name"
          label="Your name"
          value={values.name}
          error={errors.name}
          onBlur={handleBlur} onChange={handleChange} touched={touched}
        />

        <FormField
          type="email"
          name="email"
          label="Your email"
          placeholder="example@mail.com"
          value={values.email}
          error={errors.email}
          onBlur={handleBlur} onChange={handleChange} touched={touched}
        />

        <FormField
          name="message"
          label="Your message"
          placeholder="Here..."
          rows="3"
          as="textarea"
          value={values.message}
          error={errors.message}
          onBlur={handleBlur} onChange={handleChange} touched={touched}
        />

        <div className="flex justify-between">
          <div className={`${!isSubmitted && 'opacity-0'} flex items-center font-light text-sm pb-1 text-success dark:text-success transition-opacity duration-500`}>
            <HiCheckCircle />
            <span className="ml-2">Message sent</span>
          </div>
          <button
            className={`text-base font-bold ${isValid ? 'line-hover-dark dark:line-hover-light' : 'opacity-25 hover:cursor-default pb-0.5'}`}
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </div>
      </Form>
    )}
    </Formik>
  )

}
