import React from 'react';
import { VscMail } from 'react-icons/vsc';
import { FaGithub, FaLinkedinIn} from 'react-icons/fa';
import ThemeToggle from '../components/theme-toggle';
import Scrollspy from 'react-scrollspy';

export default function Sidebar(props) {

  const scrollSpyMenuItems = props.links;

  return (
    <section className="h-screen w-14 md:w-20 z-50 fixed flex flex-col justify-between py-6 text-dark dark:text-white">
      <ThemeToggle />
      <Scrollspy
        items={ scrollSpyMenuItems }
        className="hidden sm:block pl-5 text-left"
        currentClassName="!text-base !opacity-100"
        offset={ -150 }
      >
        {scrollSpyMenuItems.map((title, index) => (
          <li key={index} className="transition-all duration-300 origin-center font-sans text-sm font-light opacity-40 hover:opacity-80 w-fit h-auto py-1">
            <a href={`#${title}`} className={`${title === 'Intro' ? 'hidden' : ''}`}>{title === 'Bio' ? 'Me' : title}</a>
          </li>
        ))}
      </Scrollspy>
      <div className="hidden sm:flex flex-col gap-y-3 mx-auto">
        <a href="mailto:contact@patrick-stark.com?subject=Hey👋🏻" className="opacity-40 hover:opacity-80">
          <VscMail className="text-xl"/>
        </a>
        <a href="https://www.linkedin.com/in/patrick-stark/?locale=en_US" target="_blank" rel="noreferrer" className="opacity-40 hover:opacity-80">
          <FaLinkedinIn className="text-xl"/>
        </a>
        <a href="https://github.com/Necxes" target="_blank" rel="noreferrer" className="opacity-40 hover:opacity-80">
          <FaGithub className="text-xl"/>
        </a>
      </div>
    </section>
  );
}
