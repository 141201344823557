import React from "react";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SlideElement from "../components/slide-element";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function Slider(props) {
  return (
    <Swiper
      modules={[Navigation]}
      navigation={{
        prevEl: '.prev',
        nextEl: '.next',
      }}
      scrollbar={{ draggable: true }}
      spaceBetween={200}
      speed={800}
      loop={true}
      slidesPerView={1}
      // onSlideChange={(swiper) =>
      //   //console.log('slide change', swiper)
      // }
      // onSwiper={(swiper) => console.log(swiper)}
      className="h-full px-8 pt-8 sm:pt-16 md:pt-28 lg:pt-32 pb-10 sm:pb-52 md:pb-64 lg:pb-52 xl:pb-48 -translate-y-[11%]"
    >
      {props.slides.map((project, index) => (
        <SwiperSlide key={index}>
          {({ isActive }) => (
            <SlideElement
              title={project.title}
              description={project.description}
              techstack={project.techstack}
              demoLink={project.demoLink}
              sourceLink={project.sourceLink}
              image={project.image}
              decoElement={project.deco}
              decoExtras={project.decoExtras}
              isActive={isActive}
            />
          )}
        </SwiperSlide>
      ))}
      <div className="absolute bottom-0 lg:bottom-[5%] xl:bottom-[17%] right-[50%] xl:right-[21%] translate-x-1/2 xl:translate-x-0 flex gap-2 text-2xl text-dark dark:text-light z-40">
        <GoChevronLeft className="prev hover:cursor-pointer hover:scale-125 transition-transform ease-in-out duration-200" />
        <GoChevronRight className="next hover:cursor-pointer hover:scale-125 transition-transform ease-in-out duration-200" />
      </div>
    </Swiper>
  )
}
