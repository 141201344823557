import React from "react";

export default function Timeline (props) {
  return (
    <div className={`relative h-fit text-dark dark:text-light ${props.align === 'right' && 'translate-y-24 text-right'}`}>
      <h3 className="mx-0 w-auto font-semibold">{props.title}</h3>
      { props.data.map(( step, index) => (
        <div key={index} className={`flex gap-4 ${props.align === 'left' && 'flex-row-reverse'}`}>
          <div className="grow pt-2">
            <h4 className="mx-0 mb-0 w-auto text-lg">{step.title}</h4>
            <span className="font-light text-sm opacity-75 mb-3 block">{step.year}</span>
            <p className="mb-0"><a href={step.companyLink} target="_blank" rel="noreferrer">{step.company}</a></p>
            <p>{step.city}</p>
          </div>
          <div className="flex flex-col gap-3 pt-4">
            <div className="w-4 h-4 rounded-full opacity-75 bg-dark dark:bg-light pt-3"></div>
            <div className="w-0.5 h-full min-h-[100px] opacity-25 bg-dark dark:bg-light mx-auto"></div>
          </div>
        </div>
      ))}
      <div className={`flex items-center ${props.align === 'right' ? 'flex-row-reverse pr-0.5' : 'pl-0.5'} gap-4 pt-2`}>
        <div className="relative flex w-3 h-3">
          <div className="absolute w-full h-full origin-center bg-primary rounded-full animate-ping"></div>
          <div className="w-3 h-3 bg-primary rounded-full"></div>
        </div>
        <small className="font-light">{props.ongoing}</small>
      </div>
    </div>
  )
}
