import React from "react";
import { Parallax } from 'react-scroll-parallax';

export default function Heading(props) {
  const Title = props.headingLevel.toLowerCase();

  return (
    <Parallax translateY={[15, -15]}>
      <Title className={props.className}>{props.title}</Title>
    </Parallax>
  );
}
