import React, { useEffect } from "react";
import Heading from "../components/heading";
import Link from "../components/link";
import { Parallax } from "react-scroll-parallax";
import Slider from "../components/slider";
import { substracked, rentARetro, howToKillACactus, movieWatchlist, longestWordGame } from "../data/projects-data";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from 'react-responsive';


export default function Projects(props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.6,
  });

  const projects = [ rentARetro, substracked, howToKillACactus, movieWatchlist, longestWordGame ];
  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const image = document.getElementById("backgroundImage");
    if (inView && !isSmViewport) {
      image.style.left = "40%";
      image.style.top = "70%";
      image.style.opacity = .5;
      image.style.width = "600px";
      image.style.height = "600px";
    }
  })

  return(
    <section id={props.title} ref={ref} className="min-h-screen w-full pl-0 sm:pl-20 z-10 relative flex items-center">
      <div className="container grid grid-cols-12 gap-5 md:gap-10 mx-auto pt-20 sm:pt-30 pb-20 sm:pb-10 md:pb-0 px-12 md:px-30">
        <div className="col-span-12 md:col-span-4 lg:col-span-3 col-start-1">
          <Heading title="Some of my work" headingLevel="h1"/>
          <Parallax translateY={[15, -15]}>
            <div className="text-center md:text-left mb-4 lg:mb-0">
              <p>Here are some projects I did. I especially love creating solution and user-orientated web applications with their own unique design.</p>
              <Link to={props.next} title={props.next}/>
            </div>
          </Parallax>
        </div>
        <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-0 sm:mb-12 md:mb-8 lg:mb-0">
          <Slider slides={projects} />
        </div>
      </div>
    </section>
  )
}
