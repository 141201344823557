import React, { useEffect } from "react";
import ProfileImage from "../assets/images/profile.jpeg";
import WavingHand from "../assets/images/3d-images/waving-hand.png";
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import Heading from "../components/heading";
import Link from "../components/link";
import { useInView } from "react-intersection-observer";

export default function Bio(props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.6,
  });

  const isXsViewport = useMediaQuery({ query: '(max-width: 640px)' });
  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const image = document.getElementById("backgroundImage");
    if (inView && !isSmViewport) {
      image.style.left = "35%";
      image.style.top = "65%";
      image.style.opacity = .5;
      image.style.width = "600px";
      image.style.height = "600px";
    }
  })

  return (
    <section id={props.title} ref={ref} className="min-h-screen w-full pl-0 sm:pl-20 z-10 relative flex items-center">
      <div className="container grid grid-cols-12 gap-5 md:gap-10 mx-auto py-20 sm:py-30 px-12 md:px-30">
        <div className="col-span-8 md:col-span-5 lg:col-span-4 col-start-3 lg:col-start-2 relative">
          <Parallax translateY={[-10, 20]} easing="easeInQuad" disabled={isSmViewport ? true : false}>
            <img src={ProfileImage} alt="profile" className="w-fit mx-auto drop-shadow-lg rounded-lg" />
          </Parallax>
          <Parallax translateY={[50, -50]} className="absolute bottom-[-15%] sm:bottom-0 md:bottom-[20%] lg:bottom-0 left-[-30%] md:left-[-40%]">
            <img src={WavingHand} alt="waving hand" className="w-32 xs:w-40 md:w-40 lg:w-52 xl:w-64 brightness-125 drop-shadow"/>
          </Parallax>
        </div>
        <div className="col-span-12 md:col-span-7 lg:col-span-5 col-start-1 md:col-start-auto pt-10 md:pt-20  text-center md:text-left">
          <Heading title={`Hey,\nI'm Patrick!`} headingLevel="h1" className="new-line"/>
          <Parallax translateY={[10, -10]} easing="easeInQuad" disabled={isXsViewport ? true : false}>
            <div>
              <p>I am a front-end and full-stack developer based in Berlin, Germany. I started my career in 2015 in design and project management for digital projects. Since code has always been fascinating to me, I learned front-end development by myself and that helped me change my career to front-end developer in 2020. To improve even more, widen my front-end knowledge, and solidify my back-end knowledge, I studied at Le Wagon in Tokyo from October to December 2022 in Tokyo, Japan.</p>
              <Link to={props.next} title={props.next} />
            </div>
          </Parallax>
        </div>
      </div>
    </section>
  );
}
