import React from "react";

export default function SkillList(props) {

  function skillListItem(icon, name, index) {
    return (
      <div key={index} className="flex gap-4 lg:gap-2 xl:gap-3 items-center text-dark dark:text-light">
        <div className="">{icon}</div>
        <p className="!mb-0">{name}</p>
      </div>
    )
  }

  return (
    <div className="w-full xs:w-1/2 md:w-full p-5 sm:p-7 xl:p-8 rounded-lg h-full backdrop-blur-xl card-white dark:card-black">
      <h3 className="w-full text-center">{props.list.title}</h3>
      <div className="grid grid-cols-2 xs:grid-cols-1 lg:grid-cols-2 gap-2">
        {props.list.skills.map((item, index) => (
          skillListItem(item[0], item[1], index)
        ))}
      </div>
    </div>
  )
}
