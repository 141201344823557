import React, { useEffect } from "react";
import IntroParallaxScene from "../components/intro-parallax-scene";
import { GoChevronDown } from "react-icons/go";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from 'react-responsive';

export default function Intro(props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.8,
  });

  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const image = document.getElementById("backgroundImage");
    if (inView && !isSmViewport) {
      image.style.left = "53%";
      image.style.top = "50%";
      image.style.opacity = .9;
      image.style.width = "1000px";
      image.style.height = "1000px";
    } else if (isSmViewport) {
      image.style.left = "50%";
      image.style.top = "50%";
      image.style.opacity = .5;
      image.style.width = window.innerWidth;
      image.style.height = window.innerWidth;
    } else {
      image.style.left = "53%";
      image.style.top = "50%";
      image.style.opacity = .9;
    }
  })

  return (
    <section id={props.title} ref={ref} className="min-h-screen w-full pl-0 sm:pl-20 z-10 relative flex items-center">
      <div className="container grid grid-cols-12 gap-5 md:gap-10 mx-auto py-20 sm:py-30 px-12 md:px-30">
        <div className="col-span-10 md:col-span-8 col-start-2 md:col-start-3 flex items-center">
          <h1 className="mx-auto text-center text-6xl md:text-7xl after:!mx-auto z-30 !mb-0 after:!hidden drop-shadow-md">
            Creative front-end<br />development & design
          </h1>
        </div>
      </div>
      <div className="absolute bottom-[5%] left-1/2 -translate-x-1/2 text-2xl">
        <a href={`#${props.next}`}>
          <GoChevronDown className="motion-safe:animate-bounce"/>
        </a>
      </div>
      <IntroParallaxScene />
    </section>
  );
}
