import React from "react";
import { Field } from "formik";

export default function FormField(props) {
  const touched = props.touched;

  const validateName = value => {
    let error;
    if (!value){
      error = 'Required';
    } else if (!/^[A-Z\s]+$/i.test(value)) {
      error = 'Invalid name';
    }
    return error;
  };

  const validateEmail = value => {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  const validateMessage = value => {
    let error;
    if (!value) {
      error = 'Required';
    }
    return error;
  }
  const handleError = fieldName => {
    if (fieldName === 'name'){
      return props.error && touched.name;
    } else if (fieldName === 'email') {
      return props.error && touched.email;
    } else {
      return props.error && touched.message;
    }
  }

  const validtionCheck = name => {
    if (name === 'name') {
      return validateName;
    } else if (name === 'email') {
      return validateEmail;
    } else {
      return validateMessage;
    }
  }

  return (
    <div className="flex flex-col mb-8">
      <div className="flex justify-between align-bottom">
        <label htmlFor={props.name} className="mb-0.5 font-light">{props.label}</label>
        <div className="font-light text-sm text-error dark:text-error">
          {handleError(props.name) && props.error}
        </div>
      </div>
      <Field
        type={props.type}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        placeholder={props.placeholder}
        rows={props.rows}
        as={props.as}
        validate={validtionCheck(props.name)}
        className={`font-light mt-0 block w-full px-0.5 bg-transparent border-0 border-b-2 border-light-50 dark:border-dark-50 focus:ring-0 focus:border-dark dark:focus:border-light placeholder:text-light-50 dark:placeholder:text-dark-50 ${handleError(props.name) && 'text-error border-error dark:text-error dark:border-error'} ${!handleError(props.name) && props.value && 'border-dark dark:border-light' }`}
      />
    </div>
  );
};
