import React from "react";
import { Parallax } from "react-scroll-parallax";
import { useMediaQuery } from 'react-responsive';

export default function SlideElement(props) {
  const isXsViewport = useMediaQuery({ query: '(max-width: 640px)' });
  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  return(
    <div className="relative h-full">
      {/* Image */}
      <Parallax
        translateY={[30, -30]}
        disabled={isSmViewport ? true : false}
        className={`transition-opacity ease-in-out duration-300 sm:duration-500 delay-300 ${props.isActive ? 'opacity-100 sm:delay-500' : 'opacity-0 sm:delay-75'}`}
      >
        <img src={props.image} alt="test" className="h-auto w-full sm:w-11/12 lg:w-10/12 sm:float-right drop-shadow-lg rounded-t-lg sm:rounded-lg" />
      </Parallax>
      {/* TextContainer */}
      <Parallax
        translateY={[-20, 20]}
        disabled={isSmViewport ? true : false}
        className={`relative sm:absolute sm:bottom-[-65%] md:bottom-[-110%] lg:bottom-[-45%] xl:bottom-[-35%] transition-opacity ease-in-out duration-300 sm:duration-200 delay-300 ${props.isActive ? 'opacity-100 sm:delay-300' : 'opacity-0 sm:delay-75'}`}
      >
        <div className="p-5 sm:p-7 xl:p-8 rounded-b-lg sm:rounded-lg backdrop-blur-xl card-white dark:card-black w-full sm:w-5/6 md:w-10/12 lg:w-3/4">
          <h3 class="mb-2">{props.title}</h3>
          <p className="font-normal text-sm mb-3">{props.techstack}</p>
          <p>{props.description}</p>
          <div className="flex flex-row gap-8 text-sm">
            { props.demoLink &&
              <a href={props.demoLink} target="_blank" rel="noreferrer" className="p-0 line-hover-dark dark:line-hover-light">Demo</a>
            }
            <a href={props.sourceLink} target="_blank" rel="noreferrer" className="p-0 line-hover-dark dark:line-hover-light">Source</a>
          </div>
        </div>
      </Parallax>
      {/* Deco */}
      <Parallax
        translateY={[-30, 30]}
        disabled={isXsViewport ? true : false}
        className={`absolute top-[80%] sm:top-[-20%] md:top-[-25%] lg:top-[-20%] right-[-10%] sm:right-[-10%] md:right-[-11%] lg:right-[-5%] transition-opacity ease-in-out duration-300 ${props.isActive ? 'opacity-100 delay-700' : 'opacity-0 delay-100'}`}
      >
        <img src={props.decoElement} alt="project deco element" className={`w-28 md:32 lg:w-36 xl:w-40 drop-shadow-md sm:drop-shadow-lg ${props.decoExtras}`}/>
      </Parallax>
    </div>
  );
}
