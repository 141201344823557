import React, { useEffect } from "react";
import Heading from "../components/heading";
import { Parallax } from "react-scroll-parallax";
import { useMediaQuery } from 'react-responsive';
import ContactForm from "../components/contact-form";
import Megaphone from "../assets/images/3d-images/megaphone.png";
import { useInView } from "react-intersection-observer";

export default function Contact (props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.8,
  });

  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const image = document.getElementById("backgroundImage");
    if (inView && !isSmViewport) {
      image.style.left = "35%";
      image.style.top = "60%";
      image.style.opacity = .5;
      image.style.width = "750px";
      image.style.height = "750px";
    }
  })

  return (
    <section id={props.title} ref={ref} className="min-h-screen w-full pl-0 sm:pl-20 z-10 relative flex items-center">
      <div className="container grid grid-cols-12 gap-5 md:gap-10 mx-auto pt-0 pb-20 sm:pb-30 px-12 md:px-30">
        {/* Form */}
        <div className="col-span-12 md:col-span-7 lg:col-span-6 xl:col-span-5 col-start-1 lg:!col-start-2 order-2 md:order-1">
          <Parallax translateY={[-25, 40]} disabled={isSmViewport ? true : false}>
            <div className="p-5 sm:p-7 xl:p-8 card-white dark:card-black rounded-lg backdrop-blur-xl translate-y-16">
              <ContactForm />
            </div>
          </Parallax>
        </div>
        {/* Text */}
        <div className="col-span-12 md:col-span-5 lg:col-span-4 xl:col-span-5 order-1 md:order-2 md:pt-16">
          <Heading title={`Leave a\nmessage`} headingLevel="h1"/>
          <Parallax translateY={[-15, 15]} disabled={isSmViewport ? true : false}>
            <div className="text-center md:text-left xl:w-3/4">
              <p>Don't be shy and say "hello"!  I am always happy to hear about interesting projects, job opportunities, or ideas.</p>
              <p>If you want to know more about me and my background, check out my <a href="https://www.linkedin.com/in/patrick-stark/?locale=en_US" target="_blank" rel="noreferrer" className="font-bold">Linkedin</a>.</p>
            </div>
          </Parallax>
        </div>
      </div>
      <Parallax translateY={[-50, 50]} className="absolute bottom-[-10%] md:bottom-[-5%] xl:bottom-[0%] right-[70%] sm:right-[65%] md:right-[10%] xl:right-[20%]">
        <img src={Megaphone} alt="3d megaphone" className="xs:w-28 md:w-36 lg:w-44 xl:w-52 md:-scale-x-100 md:rotate-12 drop-shadow" />
      </Parallax>
    </section>
  );
}
