function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images
}

// Create object with all images
const images = importAll(require.context('../assets/projects/', false, /\.(png|jpe?g|svg)$/));
const deco = importAll(require.context('../assets/images/3d-images/', false, /\.(png|jpe?g|svg)$/));

export const substracked = {
  title: 'Substracked',
  description: "A Ruby on Rails web application aimed at saving users' money by tracking their online subscriptions.",
  techstack: "Ruby-on-Rails, Stimulus, Javascript, Heroku, PostgreSQL, Bootstrap",
  demoLink: 'https://www.substracked.com/',
  sourceLink: 'https://github.com/Necxes/substracked',
  image: images['substracked_logged-in_1920x1200.jpg'],
  deco: deco['calender.png'],
}

export const rentARetro = {
  title: 'Rent-a-retro',
  description: "A Ruby-on-Rails marketplace web application allowing users to lend and rent retro video games.",
  techstack: "Ruby-on-Rails, Stimulus, Javascript, Heroku, PostgreSQL, Bootstrap",
  demoLink: 'https://rent-a-retro.herokuapp.com/',
  sourceLink: 'https://github.com/Necxes/Rent-a-retro',
  image: images['rar_landing_1920x1200.jpg'],
  deco: deco['game-controller.png'],
  decoExtras: '-scale-x-100 rotate-45',
}

export const howToKillACactus = {
  title: 'How to kill a cactus',
  description: 'A quick landing page made in less than an hour during the bootcamp with only HTML and CSS.',
  techstack: "HTML, CSS",
  demoLink: 'https://necxes.github.io/landing/',
  sourceLink: 'https://github.com/Necxes/landing',
  image: images['htkac_landing_1920x1200.jpg'],
  deco: deco['cactus.png'],
  decoExtras: 'translate-x-5 md:translate-x-0 lg:translate-x-5 rotate-6',
}

export const movieWatchlist = {
  title: 'Movie watchlist',
  description: 'A Ruby-on-Rails web application to create and organize watchlists with its own database and API.',
  techstack: "Ruby-on-Rails, Javascript, Heroku, PostgreSQL, Bootstrap",
  demoLink: 'https://patrick-watch-it.herokuapp.com/',
  sourceLink: 'https://github.com/Necxes/rails-watch-list',
  image: images['watchlist_landing_1920x1200.jpg'],
  deco: deco['thump-up.png'],
  decoExtras: 'translate-x-1 -translate-y-1'
}

export const longestWordGame = {
  title: 'Longest word game',
  description: 'A Ruby-on-Rails web game application which challanges you to create the longest word possible.',
  techstack: "Ruby-on-Rails, Javascript, CSS",
  demoLink: '',
  sourceLink: 'https://github.com/Necxes/rails-longest-word-game',
  image: images['longest-word_landing_1920x1200.jpg'],
  deco: deco['robot.png'],
}
