import { SiHtml5, SiCss3, SiJavascript, SiReact, SiBootstrap, SiTailwindcss, SiRuby, SiRubyonrails, SiPhp, SiWordpress, SiPostgresql, SiHeroku, SiAdobeindesign, SiAdobephotoshop, SiAdobeillustrator, SiSketch, SiFigma, SiInvision, SiGit, SiGithub, SiJira, SiBasecamp, SiSlack } from 'react-icons/si';

export const frontendSkills = {
  title: 'Frontend',
  skills: [[<SiHtml5 />, 'HTML'], [<SiCss3 />, 'CSS'], [<SiJavascript />, 'Javascript'], [<SiReact />, 'React'], [<SiBootstrap />, 'Bootstrap'], [<SiTailwindcss />, 'TailwindCSS']],
};

export const backendSkills = {
  title: 'Backend',
  skills: [[<SiRuby />, 'Ruby'], [<SiRubyonrails />, 'Rails'], [<SiPostgresql />, 'PostgreSQL'], [<SiPhp />, 'PHP'], [<SiWordpress />, 'Wordpress'], [<SiHeroku />, 'Heroku']],
};

export const designSkills = {
  title: 'Design',
  skills: [[<SiAdobeindesign />, 'InDesign'], [<SiAdobephotoshop />, 'Photoshop'], [<SiAdobeillustrator />, 'Illustrator'], [<SiSketch />, 'Sketch'], [<SiFigma />, 'Figma'], [<SiInvision />, 'Invision']],
};

export const otherSkills = {
  title: 'Other',
  skills: [[<SiGit />, 'Git'], [<SiGithub />, 'GitHub'], [<SiJira />, 'Jira'], [<SiBasecamp />, 'Basecamp'], [<SiSlack />, 'Slack'] ],
};
