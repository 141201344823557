import React from "react";
import { Parallax } from "react-scroll-parallax";

export default function IntroParallaxScene() {
    // Import all images from directory
  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace('./', '')] = r(item);
    });
    return images
  }

  // Create object with all images
  const images = importAll(require.context('../assets/images/3d-images', false, /\.(png|jpe?g|svg)$/));

  return (
    <>
      {/* Parallax layer */}
      {/* left side  */}
      <Parallax translateY={[-20, 20]} className="absolute left-[3%] xs:left-[7%] top-[12%] sm:top-[8%] md:top-[3%] opacity-100 dark:opacity-95">
        <img src={images['coffee.png']} alt="3D coffee" className="w-40 sm:w-48 lg:w-64 drop-shadow -scale-x-100"/>
      </Parallax>
      <Parallax translateY={[-30, 30]} className="absolute left-[20%] md:left-[24%] top-[45%] opacity-100 dark:opacity-95 z-50">
        <img src={images['lightbulb.png']} alt="3D lightbulb" className="w-12 sm:w-16 lg:w-20 drop-shadow -rotate-12"/>
      </Parallax>
      <Parallax translateY={[-10, 30]} className="absolute left-[8%] bottom-[10%] sm:bottom-[15%] md:bottom-[10%] opacity-100 dark:opacity-95 ">
        <img src={images['screen.png']} alt="3D screen" className="w-24 sm:w-32 lg:w-40 drop-shadow -scale-x-100 -rotate-12"/>
      </Parallax>
      {/* middle  */}
      <Parallax translateY={[-30, 30]} className="absolute left-[60%] top-[22%] sm:top-[20%] md:top-[15%] opacity-100 dark:opacity-95 z-0">
        <img src={images['screens-fan.png']} alt="3D screens fan" className="w-40 sm:w-48 lg:w-64 drop-shadow"/>
      </Parallax>
      <Parallax translateY={[20, -20]} className="absolute left-[35%] bottom-[25%] sm:bottom-[20%] md:bottom-[14%] opacity-100 dark:opacity-95 z-0">
        <img src={images['color-fan.png']} alt="3D color fan" className="w-20 sm:w-28 lg:w-36 drop-shadow"/>
      </Parallax>
      <Parallax translateY={[-20, 20]} className="absolute left-[35%] top-[4%] md:top-[10%] opacity-100 dark:opacity-95 z-0">
        <img src={images['puzzle.png']} alt="3D puzzle" className="w-24 sm:w-36 lg:w-44 drop-shadow -scale-x-100"/>
      </Parallax>

      {/* right side */}
      <Parallax translateY={[-10, 10]} className="absolute right-[5%] bottom-[20%] md:bottom-[5%] opacity-100 dark:opacity-95">
        <img src={images['pen.png']} alt="3D pen" className="w-28 sm:w-40 lg:w-48 drop-shadow"/>
      </Parallax>
      <Parallax translateY={[60, -10]} className="absolute right-[7%] top-[8%] opacity-100 dark:opacity-95 z-0">
        <img src={images['taskboard.png']} alt="3D taskboard" className="w-20 sm:w-28 lg:w-36 drop-shadow -scale-x-100"/>
      </Parallax>
      <Parallax translateY={[-10, 50]} className="absolute right-[17%] xs:right-[23%] sm:right-[31%] bottom-[3%] xs:bottom-[10%] sm:bottom-[14%] opacity-100 dark:opacity-95 z-0">
        <img src={images['screendesign.png']} alt="3D screendesign" className="w-32 sm:w-40 lg:w-56 drop-shadow"/>
      </Parallax>
    </>
  )
}
