import React, { useEffect } from "react";
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import Heading from "../components/heading";
import Link from "../components/link";
import SkillList from "../components/skill-list";
import { frontendSkills, backendSkills, designSkills, otherSkills} from "../data/skills-data";
import Rocket from "../assets/images/3d-images/rocket.png";
import { useInView } from "react-intersection-observer";


export default function Skills(props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.8,
  });

  const isXsViewport = useMediaQuery({ query: '(max-width: 640px)' });
  const isSmViewport = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const image = document.getElementById("backgroundImage");
    if (inView && !isSmViewport) {
      image.style.left = "40%";
      image.style.top = "50%";
      image.style.opacity = .5;
      image.style.width = "750px";
      image.style.height = "750px";
    }
  })

  return (
    <section id={props.title} ref={ref} className="min-h-screen w-full pl-0 sm:pl-20 z-10 relative flex items-center">
      <div className="container grid grid-cols-12 gap-5 md:gap-10 mx-auto py-20 sm:py-30 px-12 md:px-30">
        {/* Text */}
        <div className="col-span-12 md:col-span-4 col-start-1 md:order-2">
          <Heading title="My skills" headingLevel="h1"/>
          <Parallax translateY={[15, -15]} disabled={isXsViewport ? true : false}>
            <div className="text-center md:text-left mb-4 lg:mb-0">
              <p>I believe in the phrase 'it is never too late to learn something new'. Especially learning and creating web applications with new technologies kind of became an obsession for me. Currently, I am working on improving in React and learning Angular.</p>
              <Link to={props.next} title={props.next}/>
            </div>
          </Parallax>
        </div>
        {/* Skills */}
        <div className="col-span-12 md:col-span-8 md:order-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8">
            <Parallax translateY={[-10, 10]} easing="easeInQuad" disabled={isSmViewport ? true : false}>
              <div className="flex flex-col xs:flex-row md:flex-col gap-4 sm:gap-8">
                <SkillList list={frontendSkills} />
                <SkillList list={backendSkills} />
              </div>
            </Parallax>

            <Parallax translateY={[15, -15]} easing="easeInQuad" disabled={isSmViewport ? true : false}>
              <div className="flex flex-col xs:flex-row md:flex-col gap-4 sm:gap-8 h-full">
                <SkillList list={designSkills} />
                <SkillList list={otherSkills} />
              </div>
            </Parallax>
          </div>
        </div>
      </div>

      <Parallax
        translateY={[-30, 14]}
        className="absolute bottom-[2%] md:bottom-[10%] lg:bottom-[0%] right-0 xs:right-[10%] sm:right-[5%] xl:right-[15%]"
      >
        <img src={Rocket} alt="3d rocket" className="w-28 md:w-36 lg:w-44 xl:w-52 md:-rotate-[20deg] drop-shadow" />
      </Parallax>
    </section>
  );
}
